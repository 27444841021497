
.workspaceSettings {
    display: inline-block;
    position: absolute;
    right: 0;
    margin-right: 14px;
}

.workspaceRow:not(.workspaceDrowndownActive) .workspaceSettings {
    opacity: 0;
    transition: opacity .1s ease-in-out;
}
.workspaceRow:hover .workspaceSettings {
    opacity: 1.0;
    transition: opacity .55s ease-in-out;
}

.appCard:hover {
    background-color: #02FEE1;
}